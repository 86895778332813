<template>
  <div class="portal-header">
    <div class="container p-0">
      <div
        v-if="$store.state.profile.first_name"
        class="pb-0 row user-div my-0"
      >
        <div class="col-md-2 d-none d-md-block p-0" style="align-self: center">
          <div
            v-if="this.$store.state.role == 'admin'"
            class="d-flex font-16 font-weight-bolder mr-2"
          >
            <img
              src="../assets/DSLAdminLogo2x.png"
              alt="dsl logo"
              class="pr-3 mr-4 br-blue w-100"
            />
          </div>
          <div
            v-else-if="this.$store.state.role == 'student'"
            class="d-flex font-14 font-weight-bolder kallisto-bold text-left"
          >
            Dreamscape Learn Student Portal
            <div
              v-dataanchor
              tagEvent="login"
              tagAction="authentication"
              :tagName="$store.state.profile.account_id"
              tagType="user_login"
              tagRegion="auth"
              tagSection="cognito"
              :tagText="$store.state.profile.role"
              class="hide"
              id="studentDL"
            ></div>
          </div>
          <div
            v-else-if="this.$store.state.role == 'instructor'"
            class="d-flex font-16 font-weight-bolder mr-2"
          >
            <img
              src="../assets/InstructoLogo.png"
              alt="dsl logo"
              class="pr-3 mr-3 br-blue w-100"
            />
          </div>
          <div
            v-else-if="this.$store.state.role == 'pod operator'"
            class="d-flex font-16 font-weight-bolder mr-2"
          >
            <img
              src="../assets/PodLogo.png"
              alt="dsl logo"
              class="pr-3 mr-3 br-blue w-100"
            />
          </div>
        </div>
        <div
          class="col-md-6 col-12 d-flex px-0 justify-content-end justify-content-md-start"
        >
          <navigationList type="header" :navList="getMenu()" />
        </div>
        <div class="d-none d-md-block text-right col-12 col-md-4 px-0">
          <div
            class="text-right d-flex justify-content-end font-weight-bold user-name"
          >
            <div
              class="templating-buttons-location font-weight-bold"
              style="padding-right: 26px"
              v-if="
                this.$store.state.role != 'student' &&
                (this.$store.state.viewFromMenu == 'Scheduler' ||
                  this.$store.state.viewFromMenu == 'Pod Schedule' ||
                  this.$store.state.viewFromMenu == 'Dashboard')
              "
            >
              <button
                class="font-14 cursor-pointer font-weight-bold"
                id="templateOptions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{
                  $store.state.selectedSiteForScheduler.site_name
                    .split(" ")
                    .pop()
                }}
                <img
                  class="ml-1"
                  :src="getIcon('chevron-down-solid.svg')"
                  alt="select option type"
                  width="10"
                />
              </button>
              <div
                class="dropdown-menu dropdown-menu-options p-0"
                aria-labelledby="templateOptions"
              >
                <div class="d-flex flex-column">
                  <button
                    style="
                      background: #2c2c2c !important;
                      border: none !important;
                    "
                    class="font-14 mb-0 text-left"
                    v-for="(location, lc) in $store.state.allSites"
                    :key="lc"
                    @click="
                      ($store.state.selectedSiteForScheduler = location),
                        locationUpdate(location)
                    "
                  >
                    {{ location.site_name }}
                  </button>
                </div>
              </div>
            </div>
            <div
              style="
                border-right: 1px solid #484848;
                height: 20px;
                margin-top: 7px;
              "
            ></div>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle pr-0"
                type="button"
                data-toggle="dropdown"
                style="
                  padding-left: 26px;
                  border-radius: 5px !important;
                  box-shadow: none !important;
                "
              >
                <span class="font-14">
                  Welcome, {{ $store.state.profile.first_name }}</span
                >
                <span class="caret pl-8"></span>
              </button>
              <ul
                class="dropdown-menu dropdown-menuu text-right"
                style="width: 100%; transform: translate3d(0px, 35px, 0px)"
              >
                <li>
                  <a
                    v-dataanchor
                    tagEvent="link"
                    tagAction="click"
                    tagType="internal link"
                    tagSection="main navbar"
                    tagRegion="navbar"
                    tagText="logout"
                    class="text-right logout text-lightt"
                    @click="logoutPopup()"
                    style="
                      border: none !important;
                      background: #2b2b2b;
                      box-shadow: 2px 2px 5px gray;
                      border-radius: 5px !important;
                      text-decoration: none;
                      padding: 12px 20px;
                    "
                  >
                    <span
                      v-dataanchor
                      tagEvent="link"
                      tagAction="click"
                      tagType="internal link"
                      tagSection="main navbar"
                      tagRegion="navbar"
                      tagText="logout"
                      class="font-16"
                    >
                      Logout
                      <img
                        src="../assets/icons/logout-svg.svg"
                        alt="logout icon"
                        height="12"
                        class="hide-hover pl-2"
                      />
                      <img
                        src="../assets/icons/logout-svg-gold.svg"
                        alt="logout icon"
                        height="12"
                        class="show-hover pl-2"
                      />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <hr class="mt-0 mb-lg-0" /> -->
      <div
        class="modal fade px-0"
        id="logout-confirm"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div
            class="modal-content animate-bottom"
            style="border-radius: 18px !important"
          >
            <div
              class="p-0 modal-body b-gold"
              style="border-radius: 18px !important"
            >
              <div
                class="modal-header mb-0 d-flex p-0 align-items-center import-modal-header"
              >
                <h5
                  class="modal-title font-md-18 font-16 pl-20 pb-12p pt-22 text-gold text-left kallisto-bold"
                >
                  Logout
                </h5>
              </div>
              <div class="d-flex flex-column text-center">
                <p class="font-16 font-md-18 font-weight-bold mt-38 mb-38">
                  Are you sure you want to logout?
                </p>
                <div
                  class="d-flex justify-content-center font-weight-bold mt-1 pt-2 pb-32"
                >
                  <button
                    class="mx-2 font-14 cancel os-semibold"
                    data-dismiss="modal"
                    style="border: 1px solid"
                  >
                    No, cancel
                  </button>
                  <button
                    @click="logout()"
                    class="mx-2 continue-btn font-14 os-bold"
                  >
                    Yes, logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import navigationList from "./navList.vue";
import router from "../router/index";
import Amplify, { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import $ from "jquery";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
    ClickOutside,
  },
  data() {
    return {
      toggle: false,
      title: "",
      navList: [
        {
          name: "Dashboard",
          link: "portal/dashboard",
          icon: "home-solid.svg",
          type: "route",
        },
      ],
      navListpod: [
        {
          name: "Pod Schedule",
          link: "portal",
          icon: "home-solid.svg",
          type: "route",
        },
        {
          name: "Pod Check-in",
          link: "pod-check-in",
          icon: "qr-code.svg",
          type: "modal",
        },
      ],
      navListInstructor: [
        {
          name: "Dashboard",
          link: "portal",
          icon: "home-solid.svg",
          type: "route",
        },
        {
          name: "Pod Schedule",
          link: "portal",
          icon: "clock-regular.svg",
          type: "route",
        },
      ],
      navListAdmin: [
        {
          name: "Dashboard",
          link: "portal",
          icon: "home-solid.svg",
          type: "route",
        },
        {
          name: "Pod Schedule",
          link: "portal",
          icon: "clock-regular.svg",
          type: "route",
        },
        {
          name: "Scheduler",
          link: "portal",
          icon: "calendar-alt-solid.svg",
          type: "route",
        },
      ],
    };
  },
  components: {
    navigationList,
  },
  mounted() {
    if (this.$store.state.role == "admin") {
      this.title = "Dreamscape Learn Admin Portal";
    } else if (this.$store.state.role == "instructor") {
      this.title = "Dreamscape Learn Instructor Portal";
    } else if (this.$store.state.role == "pod operator") {
      this.title = "Dreamscape Learn Pod Operator Portal";
    } else if (this.$store.state.role == "student") {
      $("#studentDL").click();
      this.title = "Dreamscape Learn Student Portal";
    }
  },
  methods: {
    async locationUpdate(location) {
      // update courses based on site_id https://dev-api.dreamscapelearn.online/api/{site_id}/dsl/common/asu/courses
      this.$store.state.refresh = false;
      this.$store.state.selected_site_id = location.site_id;
      this.$store.state.allCourses = await API.get(
        "proxy",
        `/${location.site_id}/dsl/common/asu/courses`
      );

      // update title-maping https://dev-api.dreamscapelearn.online/api/ASU00003/dsl/common/
      const schedules = await API.get(
        "proxy",
        `/${location.site_id}/dsl/common/title-mapping`
      );
      var myObject = {};
      schedules.title_mapping[0].pods.forEach((pod) => {
        myObject[pod.pod_number] = {
          gear_id: pod.gearups[0].gearup_id,
          gearups: pod.gearups,
          titles: pod.titles,
          capacity: pod.gearups[0].max_users,
        };
      });

      this.$store.state.scheduler_pods = myObject;
      if (
        schedules.title_mapping?.length > 0 &&
        schedules.title_mapping[0].pods?.length > 0 &&
        schedules.title_mapping[0].pods[0].gearups?.length > 0
      ) {
        this.$store.state.calendarSelectedGearup =
          schedules.title_mapping[0].pods[0].gearups[0];
      }
      Amplify.configure({
        aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
        aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
        aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
        aws_user_pools_web_client_id:
          process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        oauth: {},
        API: {
          endpoints: [
            {
              name: "asulink",
              endpoint: process.env.VUE_APP_ASULINK_API_URL.replace(
                "site_id",
                this.$store.state.selected_site_id
              ),
              custom_header: async () => {
                return {
                  Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
                  "x-api-key":
                    this.$store.state.selected_site_id == "ASU00003"
                      ? process.env.VUE_APP_ASULINK_API_KEY_ASU03
                      : this.$store.state.selected_site_id == "ASU00004"
                      ? process.env.VUE_APP_ASULINK_API_KEY_ASU04
                      : process.env.VUE_APP_ASULINK_API_KEY,
                };
              },
            },
            {
              name: "proxy",
              endpoint: `${process.env.VUE_APP_PROXY_API_URL}`,
              custom_header: async () => {
                return {
                  Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
                  "x-api-key":
                    this.$store.state.selected_site_id == "ASU00003"
                      ? process.env.VUE_APP_ASULINK_API_KEY_ASU03
                      : this.$store.state.selected_site_id == "ASU00004"
                      ? process.env.VUE_APP_ASULINK_API_KEY_ASU04
                      : process.env.VUE_APP_ASULINK_API_KEY,
                };
              },
            },
          ],
        },
      });
      await this.$store.dispatch("setWhiteListUsers");
      // update schedulers https://api.asu00003.asudeva.asu.techscapelearn.com/scheduler/sessions/ASU00003
      let myData = {
        selected_site_id: location.site_id,
        podSessionDate: this.$store.state.podSessionDate,
      };
      this.$store
        .dispatch("setSchedulerSessions", myData)
        .then(() => {
          this.$store.state.loader = false;
          this.$store.commit("REFRESH");
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$store.commit("REFRESH");
        });
    },
    getMenu() {
      if (this.$store.state.role == "admin") {
        return this.navListAdmin;
      } else if (this.$store.state.role == "instructor") {
        return this.navListInstructor;
      } else if (this.$store.state.role == "pod operator") {
        return this.navListpod;
      }
      return this.navList;
    },
    returnTagText(name) {
      return `Welcome, ${name}`;
    },
    pushToDataLayer() {
      window.dataLayer.push({ event: "test" });
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    openDropdown() {
      this.toggle = !this.toggle;
      var element = document.getElementById("toggleIcon");
      if (element.classList) {
        element.classList.toggle("reverse");
      } else {
        // For IE9
        var classes = element.className.split(" ");
        var i = classes.indexOf("reverse");

        if (i >= 0) classes.splice(i, 1);
        else classes.push("reverse");
        element.className = classes.join(" ");
      }
    },
    hide() {
      var element = document.getElementById("toggleIcon");
      this.toggle = false;
      element.classList.remove("reverse");
    },
    logoutPopup() {
      $("#logout-confirm").modal();
    },
    async logout() {
      $("#logout-confirm").modal("hide");
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
      router.push({ path: "/portal" });
      this.$store.state.role = "";
      this.$store.state.viewFromMenu = "Dashboard";
    },
  },
};
</script>
<style scoped lang="scss">
.templating-buttons-location {
  font-size: 14px;
  padding: 6px 12px;
  height: 35px;
  color: #fff;
  display: flex;
  align-items: center;
  &hover {
    background-color: #f5f5f5;
    color: #151515;
  }
}
.cancel,
.continue-btn {
  border-radius: 8px !important;
  height: 35px !important;
  width: 108px;
}
.cancel:hover {
  color: black !important;
  background: white;
}
.portal-header {
  position: sticky;
  top: 0;
  background: #151515;
  box-shadow: 0px 3px 6px #111;
  z-index: 999;
}
.user-div {
  margin: 28px 0px;
  height: 65px;
}

.dropdown-toggle:active {
  background-color: #454545 !important;
}
.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #454545 !important;
}
.dropdown-toggle {
  border: none !important;
  color: white !important;
  font-weight: bold;
}
.reverse {
  transform: none !important;
}
.reversepg {
  transform: rotate(180deg);
}
#toggleIcon {
  transform: rotate(180deg);
}
.dropdown {
  text-align: start;
  z-index: 4;
  align-items: flex-end;
}
.customDropDown {
  top: 30%;
}
.dropdown a {
  background: #f5f5f5;
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  cursor: pointer;
  color: #2a2a2a;
  list-style: none;
}
.Feature {
  background: red;
  color: white;
  margin-left: 10px;
  padding: 0 4px;
  border-radius: 3px;
}

hr {
  background-color: grey !important;
  height: 0.5px;
  opacity: 0.3;
}
.swpg {
  cursor: pointer;
  background: #04326b;
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .cancel,
  .continue-btn {
    height: 35px !important;
    width: 108px;
  }
  .swpg {
    margin: 0 15px;
  }
  .dropdown {
    text-align: left;
  }
  .portal-header {
    position: absolute;
    right: 0;
    background: transparent;
    box-shadow: none;
  }
}
.dropdown-menuu {
  box-shadow: none !important;
}
.br-blue {
  border-right: 1px solid #0e4f74;
}
</style>
